<template>
  <a-button class="editable-add-btn" @click="openModal" style="margin-bottom: 16px">Изменить порядок</a-button>
  <a-modal
    class="section-order-modal"
    title="Редактировать"
    :visible="modalVisible"
    :closable="true"
    :width="300"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="updateData"
    @cancel="resetForm"
  >
    <a-tree
      class="draggable-tree"
      v-model:expandedKeys="expandedKeys"
      draggable
      @dragenter="onDragEnter"
      @drop="onDrop"
    >
      <a-tree-node v-for="item in treeData" :key="item.i">
        <template #title>
          <span style="color: #333">{{ item.content }}...&nbsp;({{ item.type}})</span>
        </template>
      </a-tree-node>
    </a-tree>
  </a-modal>
</template>

<script setup>
import {ref, reactive, watch} from 'vue';
import apiClient from "@/services/axios";
import {notification} from "ant-design-vue";
import {useRoute} from "vue-router";

const emit = defineEmits(['reload']);

const props = defineProps({
  tree: {
    type: Object,
    default: () => {},
  },
})

let loading = ref(false),
  route = useRoute(),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  treeData = ref([]),
  expandedKeys = ref([]),
  section = reactive({
    type: '',
    order: ''
  });

const
  openModal = () => {
    modalVisible.value = true;
  },
  resetForm = () => {
    Object.assign(section, {
      type: '',
      order: ''
    });
    modalVisible.value = false;
  },
  onDragEnter = info => {
    // console.log(info);
  },
  onDrop = info => {
    const move = (from, to, arr) => {
      const newArr = [...arr];

      const item = newArr.splice(from, 1)[0];
      newArr.splice(to, 0, item);

      return newArr;
    }
    const dragKey = info.dragNode.pos.split('-')[1];
    const dropPos = info.node.pos.split('-')[1];
    treeData.value = move(dragKey, dropPos, treeData.value)
  },
  updateData = () => {
    confirmLoading.value = true;
    apiClient.put(`/documentations/section-sort?documentation=${route.params.page}`, { data: treeData.value })
      .then(response => {
        if (response.data.data.success) {
          resetForm()
          notification.success({
            message: 'Список успешно обновлен',
          })
        }
      }).finally(() => {
      modalVisible.value = false;
      confirmLoading.value = false;
      emit('reload')
    })
  }

  watch(modalVisible, (modal) => {
    if (modal) {
      treeData.value = props.tree
    }
  })
</script>

<style lang="scss" scoped>
</style>
